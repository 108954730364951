import Script from 'next/script'
import Layout from 'components/layout/layout'
import PageViews from 'utils/analytics/page-views'
import { useState } from 'react'
import { Provider } from 'next-auth/client'
import { ModalProvider } from 'components/modals/provider'
import { StateContextProvider } from 'utils/state'
import { TooltipContextProvider } from 'components/tooltip/provider'
import { GA_TRACKING_ID } from 'utils/analytics/gtag'
// import { ALGOLIA_APP_ID, ALGOLIA_API_KEY } from 'utils/analytics/algolia'
import { GTM_ID } from 'utils/analytics/gtm'
// import Chat from 'components/chat/chat'

import 'styles/globals.scss'

function App({ Component, pageProps }) {
    const [scriptsLoaded, setScriptsLoaded] = useState(0)
    const totalScripts = GTM_ID === '000' ? 1 : 1

    const loadHandles = () => {
        setScriptsLoaded(scriptsLoaded + 1)
    }

    return (
        <>
            {/* Hotjar */}
            <Script id="hotjar-script" strategy="lazyOnload">
                {`(function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:2164904,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
            </Script>            
            {/* Tag Manager */}
            <Script id="manager-script" strategy="lazyOnload">
                {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${GTM_ID}');`}
            </Script>
            {/* Google Analytics */}
            <Script
            id="analytics-script"
                async
                strategy="lazyOnload"
                onLoad={loadHandles}
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
            />
            {/* Facebook Pixel */}
            <Script id="pixel-script">
                {`!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');`}
            </Script>
            {/* Crisp 
            <Script id="crisp-script" strategy="lazyOnload">
                {`window.$crisp=[];window.CRISP_WEBSITE_ID="998c2599-4c7c-4ab8-996f-578c157b5e67";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}
            </Script>
            */}
            <Provider session={pageProps.session}>
                <StateContextProvider>
                    <PageViews scriptsLoaded={scriptsLoaded === totalScripts}>
                        <ModalProvider>
                            <TooltipContextProvider>
                                <Layout>
                                    <Component scriptsLoaded={scriptsLoaded === totalScripts} {...pageProps} />
                                    {/* <Chat /> */}
                                </Layout>
                            </TooltipContextProvider>
                        </ModalProvider>
                    </PageViews>
                </StateContextProvider>
            </Provider>
        </>
    )
}

export default App
