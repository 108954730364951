// import { useEffect } from 'react'
import dynamic from 'next/dynamic'
import { useSession } from 'next-auth/client'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Heading from 'components/heading/heading'
import Newsletter from 'components/newsletter/newsletter'
import { getIsMinimal } from 'utils/helpers'
import { cn, getRoute } from 'utils/helpers'
import styles from './footer.module.scss'
import Svg from 'components/svg/svg'
import { useModal } from 'components/modals/provider'
const Delivery = dynamic(() => import('components/modals/contact/contact'))

export default function Footer() {
    const [session] = useSession()
    const { pathname } = useRouter()
    const minimal = getIsMinimal(pathname)
    const moveUp = pathname.includes('/preke/') || pathname.includes('/preke/')
    const showDataContract = session && session.user && session.user.account_id
    const title = 'Gaukite nuolaidų iki 10% kodą į el. paštą!'
    const { showModal } = useModal()

    // useEffect(() => {
    //     showModal(Delivery)
    // }, [])

    const handleClick = () => {
        showModal(Delivery)
    }

    return (
        <footer className={styles.footer}>
            <div className={cn(styles.contactBtn, moveUp && styles.contactBtnUp)} onClick={handleClick}></div>
            <div className={styles.footerWrap}>
                <div className={styles.footerBg}>
                    <Svg name="paper-top" />
                </div>
                <div className={styles.footerContent}>
                    {!minimal && (
                        <>
                            <div className={styles.newsletter}>
                                <Heading tag="h3" size="30" className="mb12">
                                    {title}
                                </Heading>
                                <p className="mb42">*nuolaidų kodas galioja tik tam tikrų parduotuvių prekėms</p>
                                <div className={styles.input}>
                                    <Newsletter component="input" />
                                </div>
                            </div>
                            <nav className={styles.cols}>
                                <div className={cn(styles.col, 'mb72')}>
                                    <Heading tag="b" size="18" className="mb12">
                                        Lietuviška prekė
                                    </Heading>
                                    <p>
                                        © {new Date().getFullYear()}. Visos teisės saugomos.
                                        <br />
                                        MB „Pavojingos idėjos“, <br />
                                        Vilniaus g. 9-1, LT-01102 Vilnius, <br />
                                        Įmonės kodas: 304496867, <br />
                                        info@lietuviskapreke.lt
                                    </p>
                                </div>
                                <div className={cn(styles.col, 'mb72')}>
                                    <Heading tag="b" size="18" className="mb12">
                                        Informacija
                                    </Heading>
                                    <Link href={getRoute('faq')}>D.U.K.</Link>
                                    <Link href={getRoute('delivery')}>Siuntimas</Link>
                                    <Link href={getRoute('return')}>Grąžinimas</Link>
                                    <Link href={getRoute('loyalty-program')}>Bičiulių klubas</Link>
                                </div>
                                <div className={cn(styles.col, 'mb72')}>
                                    <Heading tag="b" size="18" className="mb12">
                                        Pirkėjams
                                    </Heading>
                                    <Link href={getRoute('about')}>Apie mus</Link>
                                    <Link href={getRoute('shops')}>Parduotuvės</Link>
                                    <Link href={getRoute('search')}>Paieška</Link>
                                    {/*<Link href={getRoute('themes')}>Prekės pagal temą</Link>
                                    <Link href={getRoute('suggestions')}>Personalizuoti pasiūlymai</Link>*/}
                                    <Link href={getRoute('buyerblog')}>Tinklaraštis</Link>
                                </div>
                                <div className={cn(styles.col, 'mb72')}>
                                    <Heading tag="b" size="18" className="mb12">
                                        Šventės
                                    </Heading>
                                    <Link href="/k/valentino-diena">Valentino diena</Link>
                                    <Link href="/moters-diena">Moters diena</Link>
                                    <Link href="/kaledos">Kalėdos</Link>
                                    <Link href="/k/velykos">Velykos</Link>
                                    <Link href="/k/motinos-diena">Motinos diena</Link>
                                    <Link href="/k/tevo-diena">Tėvo diena</Link>
                                </div>
                                <div className={cn(styles.col, 'mb72')}>
                                    <Heading tag="b" size="18" className="mb12">
                                        Pardavėjams
                                    </Heading>
                                    <Link href={getRoute('landing')}>Prekiaukite per Lietuviskapreke.lt</Link>
                                    <Link href={getRoute('blog')}>Tinklaraštis</Link>
                                </div>
                                <div className={cn(styles.col, 'mb72')}>
                                    <Heading tag="b" size="18" className="mb12">
                                        Bendraukime
                                    </Heading>
                                    <a rel="noreferrer noopener" href={getRoute('facebook')} target="_blank">
                                        Facebook
                                    </a>
                                    <a rel="noreferrer noopener" href={getRoute('instagram')} target="_blank">
                                        Instagram
                                    </a>
                                    <a href="javascrip:;" onClick={handleClick}>Susisiekite</a>
                                </div>
                            </nav>
                        </>
                    )}
                    <div className={styles.requisites}>
                        <Link href={getRoute('privacy-policy')}>Privatumo politika</Link>
                        <Link href={getRoute('purchase-sale-rules')}>Pirkimo-pardavimo taisyklės</Link>
                        {showDataContract && (
                            <Link href={getRoute('data-processing-contract')}>Duomenų tvarkymo sutartis</Link>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    )
}
