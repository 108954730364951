const Groups = [
    {
        url: '/g/maistas-ir-gerimai',
        name: 'Maistas <i> ir gėrimai</i>',
        id: 1,
        children: [
            {
                url: '/k/maistas-ir-gerimai/maistas',
                name: 'Maistas',
                count: 231,
                children: [
                    {
                        url: '/k/maistas-ir-gerimai/maistas/funkcinis-maistas',
                        name: 'Funkcinis maistas',
                        count: 788
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/uzkandziai',
                        name: 'Užkandžiai',
                        count: 26
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/saldainiai-ir-sokoladas',
                        name: 'Saldainiai ir šokoladas',
                        count: 22
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/pagardai-ir-padazai',
                        name: 'Pagardai ir padažai',
                        count: 6
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/seklos-priekoniai',
                        name: 'Sėklos, priekoniai',
                        count: 2
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/uogienes-dzemai',
                        name: 'Uogienės, džemai',
                        count: 11
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/maisto-rinkiniai',
                        name: 'Maisto rinkiniai',
                        count: 25
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/medus-ir-medaus-gaminiai',
                        name: 'Medus ir medaus gaminiai',
                        count: 46
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/kudikiu-maistas',
                        name: 'Kūdikių maistas',
                        count: 78
                    },
                    {
                        url: '/k/maistas-ir-gerimai/maistas/aliejai',
                        name: 'Aliejai',
                        count: 1
                    }
                ]
            },
            {
                url: '/k/maistas-ir-gerimai/gerimai',
                name: 'Gėrimai',
                count: 28,
                children: [
                    {
                        url: '/k/maistas-ir-gerimai/gerimai/kava',
                        name: 'Kava',
                        count: 4
                    },
                    {
                        url: '/k/maistas-ir-gerimai/gerimai/arbata',
                        name: 'Arbata',
                        count: 1
                    },
                    {
                        url: '/k/maistas-ir-gerimai/gerimai/salti-gerimai',
                        name: 'Šalti gėrimai',
                        count: 23
                    }
                ]
            }
        ]
    },
    {
        url: '/g/namai-ir-gyvenimas',
        name: '<i>Namai ir </i>gyvenimas',
        id: 2,
        children: [
            {
                url: '/k/namai-ir-interjeras',
                name: 'Namai ir interjeras',
                count: 737,
                children: [
                    {
                        url: '/k/namai-ir-interjeras/vonios-kambarys',
                        name: 'Vonios kambarys',
                        count: 30
                    },
                    {
                        url: '/k/namai-ir-interjeras/patalyne',
                        name: 'Patalynė',
                        count: 11
                    },
                    {
                        url: '/k/namai-ir-interjeras/kilimai',
                        name: 'Kilimai',
                        count: 5
                    },
                    {
                        url: '/k/namai-ir-interjeras/baldai',
                        name: 'Baldai',
                        count: 2
                    },
                    {
                        url: '/k/namai-ir-interjeras/namu-dekoras',
                        name: 'Namų dekoras',
                        count: 449
                    },
                    {
                        url: '/k/namai-ir-interjeras/irankiai',
                        name: 'Įrankiai',
                        count: 1
                    },
                    {
                        url: '/k/namai-ir-interjeras/virtuve-ir-valgomasis',
                        name: 'Virtuvė ir valgomasis',
                        count: 172
                    },
                    {
                        url: '/k/namai-ir-interjeras/lauke-ir-sode',
                        name: 'Lauke ir sode',
                        count: 11
                    },
                    {
                        url: '/k/namai-ir-interjeras/daiktu-laikymas-ir-organizavimas',
                        name: 'Daiktų laikymas ir organizavimas',
                        count: 10
                    }
                ]
            },
            {
                url: '/k/vonios-ir-grozio-reikmenys',
                name: 'Vonios ir grožio reikmenys',
                count: 51,
                children: [
                    {
                        url: '/k/vonios-ir-grozio-reikmenys/vonios-reikmenys',
                        name: 'Vonios reikmenys',
                        count: 1
                    },
                    {
                        url: '/k/vonios-ir-grozio-reikmenys/plauku-prieziura',
                        name: 'Plaukų priežiūra',
                        count: 11
                    },
                    {
                        url: '/k/vonios-ir-grozio-reikmenys/asmens-prieziura',
                        name: 'Asmens priežiūra',
                        count: 2
                    },
                    {
                        url: '/k/vonios-ir-grozio-reikmenys/odos-prieziura',
                        name: 'Odos priežiūra',
                        count: 19
                    },
                    {
                        url: '/k/vonios-ir-grozio-reikmenys/muilai',
                        name: 'Muilai',
                        count: 13
                    },
                    {
                        url: '/k/vonios-ir-grozio-reikmenys/spa',
                        name: 'SPA',
                        count: 1
                    }
                ]
            },
            {
                url: '/k/meno-dirbiniai-ir-kolekciniai-daiktai',
                name: 'Meno dirbiniai ir kolekciniai daiktai',
                count: 14,
                children: [
                    {
                        url: '/k/meno-dirbiniai-ir-kolekciniai-daiktai/kolekciniai-daiktai',
                        name: 'Kolekciniai daiktai',
                        count: 5
                    },
                    {
                        url: '/k/meno-dirbiniai-ir-kolekciniai-daiktai/piesiniai-iliustraijos',
                        name: 'Piešiniai, iliustraijos',
                        count: 1
                    },
                    {
                        url: '/k/meno-dirbiniai-ir-kolekciniai-daiktai/rankdarbiai',
                        name: 'Rankdarbiai',
                        count: 8
                    }
                ]
            },
            {
                url: '/k/augintiniu-reikmenys',
                name: 'Augintinių reikmenys',
                count: 38,
                children: [
                    {
                        url: '/k/augintiniu-reikmenys/augintiniu-antkakliai-ir-pavadeliai',
                        name: 'Augintinių antkakliai ir pavadėliai',
                        count: 21
                    },
                    {
                        url: '/k/augintiniu-reikmenys/baldai-augintiniams',
                        name: 'Baldai augintiniams',
                        count: 16
                    }
                ]
            },
            {
                url: '/k/pirties-reikmenys',
                name: 'Pirties reikmenys',
                count: 38,
                children: [
                    {
                        url: '/k/pirties-reikmenys/pirties-apranga',
                        name: 'Pirties apranga',
                        count: 21
                    },
                    {
                        url: '/k/pirties-reikmenys/pirties-laikrodziai',
                        name: 'Pirties laikrodžiai',
                        count: 16
                    },
                    {
                        url: '/k/pirties-reikmenys/pirties-aksesuarai',
                        name: 'Pirties aksesuarai',
                        count: 16
                    }
                ]
            }
        ]
    },
    {
        url: '/g/drabuziai-ir-avalyne',
        name: 'Apranga<i> ir avalynė</i>',
        id: 3,
        children: [
            {
                url: '/k/rankines-kuprines-deklai',
                name: 'Rankinės, kuprinės, dėklai',
                count: 179,
                children: [
                    {
                        url: '/k/rankines-kuprines-deklai/deklai',
                        name: 'Dėklai',
                        count: 8
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/kuprines',
                        name: 'Kuprinės',
                        count: 91
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/kosmetikos-ir-higienos-reikmenu-laikymas',
                        name: 'Kosmetikos ir higienos reikmenų laikymas',
                        count: 6
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/maisto-krepsiai-saltkrepsiai',
                        name: 'Maisto krepšiai, šaltkrepšiai',
                        count: 1
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/rankines',
                        name: 'Rankinės',
                        count: 57
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/pirkiniu-krepsiai',
                        name: 'Pirkinių krepšiai',
                        count: 4
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/pinigines-korteliu-deklai',
                        name: 'Piniginės, kortelių dėklai',
                        count: 8
                    }
                ]
            },
            {
                url: '/k/apranga',
                name: 'Apranga',
                count: 236,
                children: [
                    {
                        url: '/k/apranga/berniuku-apranga',
                        name: 'Berniukų apranga',
                        count: 12
                    },
                    {
                        url: '/k/apranga/mergaiciu-apranga',
                        name: 'Mergaičių apranga',
                        count: 24
                    },
                    {
                        url: '/k/apranga/apranga-vyrams',
                        name: 'Apranga vyrams',
                        count: 45
                    },
                    {
                        url: '/k/apranga/unisex-apranga-suaugusiems',
                        name: '„Unisex“ apranga suaugusiems',
                        count: 11
                    },
                    {
                        url: '/k/apranga/unisex-apranga-vaikams',
                        name: '„Unisex“ apranga vaikams',
                        count: 56
                    },
                    {
                        url: '/k/apranga/apranga-moterims',
                        name: 'Apranga moterims',
                        count: 63
                    }
                ]
            },
            {
                url: '/k/avalyne',
                name: 'Avalynė',
                count: 31,
                children: [
                    {
                        url: '/k/avalyne/berniuku-avalyne',
                        name: 'Berniukų avalynė',
                        count: 2
                    },
                    {
                        url: '/k/avalyne/mergaiciu-avalyne',
                        name: 'Mergaičių avalynė',
                        count: 2
                    },
                    {
                        url: '/k/avalyne/avalynes-aksesuarai',
                        name: 'Avalynės aksesuarai',
                        count: 4
                    },
                    {
                        url: '/k/avalyne/vyriska-avalyne',
                        name: 'Vyriška avalynė',
                        count: 4
                    },
                    {
                        url: '/k/avalyne/unisex-avalyne-suaugusiems',
                        name: 'Unisex avalynė suaugusiems',
                        count: 4
                    },
                    {
                        url: '/k/avalyne/unisex-avalyne-vaikams',
                        name: 'Unisex avalynė vaikams',
                        count: 1
                    },
                    {
                        url: '/k/avalyne/moteriska-avalyne',
                        name: 'Moteriška avalynė',
                        count: 9
                    }
                ]
            }
        ]
    },
    {
        url: '/g/papuosalai-ir-aksesuarai',
        name: '<i>Papuošalai ir </i>aksesuarai',
        id: 4,
        children: [
            {
                url: '/k/rankines-kuprines-deklai',
                name: 'Rankinės, kuprinės, dėklai',
                count: 179,
                children: [
                    {
                        url: '/k/rankines-kuprines-deklai/deklai',
                        name: 'Dėklai',
                        count: 8
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/kuprines',
                        name: 'Kuprinės',
                        count: 91
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/kosmetikos-ir-higienos-reikmenu-laikymas',
                        name: 'Kosmetikos ir higienos reikmenų laikymas',
                        count: 6
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/maisto-krepsiai-saltkrepsiai',
                        name: 'Maisto krepšiai, šaltkrepšiai',
                        count: 1
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/rankines',
                        name: 'Rankinės',
                        count: 57
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/pirkiniu-krepsiai',
                        name: 'Pirkinių krepšiai',
                        count: 4
                    },
                    {
                        url: '/k/rankines-kuprines-deklai/pinigines-korteliu-deklai',
                        name: 'Piniginės, kortelių dėklai',
                        count: 8
                    }
                ]
            },
            {
                url: '/k/aksesuarai',
                name: '﻿Aksesuarai',
                count: 251,
                children: [
                    {
                        url: '/k/aksesuarai/aksesuarai-kudikiams',
                        name: 'Aksesuarai kūdikiams',
                        count: 2
                    },
                    {
                        url: '/k/aksesuarai/pirstines',
                        name: 'Pirštinės',
                        count: 6
                    },
                    {
                        url: '/k/aksesuarai/plauku-aksesuarai',
                        name: 'Plaukų aksesuarai',
                        count: 55
                    },
                    {
                        url: '/k/aksesuarai/kepures-ir-skrybeles',
                        name: 'Kepurės ir skrybėlės',
                        count: 6
                    },
                    {
                        url: '/k/aksesuarai/raktu-pakabukai-ir-dirzeliai',
                        name: 'Raktų pakabukai ir dirželiai',
                        count: 3
                    },
                    {
                        url: '/k/aksesuarai/prisegami-zenkliukai',
                        name: 'Prisegami ženkliukai',
                        count: 136
                    },
                    {
                        url: '/k/aksesuarai/salikai-ir-skraistes',
                        name: 'Šalikai ir skraistės',
                        count: 9
                    },
                    {
                        url: '/k/aksesuarai/kostiumu-ir-kaklaraisciu-aksesuarai',
                        name: 'Kostiumų ir kaklaraiščių aksesuarai',
                        count: 9
                    }
                ]
            },
            {
                url: '/k/papuosalai',
                name: 'Papuošalai',
                count: 325,
                children: [
                    {
                        url: '/k/papuosalai/apyrankes',
                        name: 'Apyrankės',
                        count: 89
                    },
                    {
                        url: '/k/papuosalai/auskarai',
                        name: 'Auskarai',
                        count: 108
                    },
                    {
                        url: '/k/papuosalai/kaklo-papuosalai',
                        name: 'Kaklo papuošalai',
                        count: 93
                    },
                    {
                        url: '/k/papuosalai/ziedai',
                        name: 'Žiedai',
                        count: 5
                    },
                    {
                        url: '/k/papuosalai/laikrodziai',
                        name: 'Laikrodžiai',
                        count: 5
                    },
                    {
                        url: '/k/papuosalai/papuosalu-komplektai',
                        name: 'Papuošalų komplektai',
                        count: 4
                    },
                    {
                        url: '/k/papuosalai/sages-seges-ir-segtukai',
                        name: 'Sagės, segės ir segtukai',
                        count: 11
                    }
                ]
            }
        ]
    },
    {
        url: '/g/pramogos-ir-zaislai',
        name: 'Pramogos<i> ir žaislai</i>',
        id: 5,
        children: [
            {
                url: '/k/zaislai-ir-zaidimai',
                name: 'Žaislai ir žaidimai',
                count: 85,
                children: [
                    {
                        url: '/k/zaislai-ir-zaidimai/zaidimai-ir-deliones',
                        name: 'Žaidimai ir dėlionės',
                        count: 22
                    },
                    {
                        url: '/k/zaislai-ir-zaidimai/sportas-ir-poilsis-lauke',
                        name: 'Sportas ir poilsis lauke',
                        count: 13
                    },
                    {
                        url: '/k/zaislai-ir-zaidimai/zaislai',
                        name: 'Žaislai',
                        count: 38
                    }
                ]
            },
            {
                url: '/k/valentino-diena',
                name: 'Valentino diena',
                count: 4,
                children: []
            },
            {
                url: '/k/vestuves',
                name: 'Vestuvės',
                count: 4,
                children: [
                    {
                        url: '/k/vestuves/vestuviu-kvietimai-ir-spauda',
                        name: 'Vestuvių kvietimai ir spauda',
                        count: 4
                    }
                ]
            },

            {
                url: '/k/velykos',
                name: 'Velykos',
                count: 4,
                children: []
            },
            // {
            //     url: '/k/valstybines-sventes',
            //     name: 'Valstybinės šventės',
            //     count: 4,
            //     children: []
            // },
            {
                url: '/k/lietuvos-atributika',
                name: 'Lietuvos atributika',
                count: 4,
                children: []
            },
            {
                url: '/k/motinos-diena',
                name: 'Motinos diena',
                count: 4,
                children: []
            },
            {
                url: '/k/tevo-diena',
                name: 'Tėvo diena',
                count: 4,
                children: []
            },
            {
                url: '/k/krikstynos',
                name: 'Krikštynos',
                count: 4,
                children: []
            },
            {
                url: '/kaledos',
                name: 'Kalėdos',
                count: 4,
                children: []
            },
            {
                url: '/k/mokytojams-ir-mokiniams',
                name: 'Mokytojams ir mokiniams',
                count: 794,
                children: []
            },
            {
                url: '/k/rastines-reikmenys',
                name: 'Raštinės reikmenys',
                count: 54,
                children: [
                    {
                        url: '/k/rastines-reikmenys/rastines-reikmenys',
                        name: 'Raštinės reikmenys',
                        count: 54
                    }
                ]
            },
            {
                url: '/k/knygos-filmai-muzika',
                name: 'Knygos, filmai, muzika',
                count: 1,
                children: [
                    {
                        url: '/k/knygos-filmai-muzika/knygos',
                        name: 'Knygos',
                        count: 1
                    }
                ]
            },
            {
                url: '/k/elektronika-ir-priedai',
                name: 'Elektronika ir priedai',
                count: 3,
                children: [
                    {
                        url: '/k/elektronika-ir-priedai/elektronikos-deklai',
                        name: 'Elektronikos dėklai',
                        count: 3
                    }
                ]
            }
        ]
    },
    {
        url: '/isskirtinumai',
        name: 'Išskirtinumai',
        id: 0,
        children: []
    }
]

export default Groups
