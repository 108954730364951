// 475014368973443
export const FB_PIXEL_ID = 1297119844862984 //process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

export const init = () => {
    if (window.fbq) {
        window.fbq('init', 1297119844862984); //FB_PIXEL_ID)
    }
}

export const revoke = () => {
    if (window.fbq) {
        window.fbq('consent', 'revoke')
    }
}

export const grant = () => {
    if (window.fbq) {
        window.fbq('consent', 'grant')
    }
}

export const pageView = () => {
    if (window.fbq) {
        window.fbq('track', 'PageView')
    }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
    window.fbq('track', name, options)
    // console.log('track', name, options)
}
